import { Accordion, AccordionWrapper } from '../seo-content/seo_content';

// import { Helmet } from "react-helmet";
import { withAppContext } from 'src/store/initAppContext';
import BlackJackSEO39 from './BlackJackSEO39';
import brandDomains from 'src/config/hostConfig';
import BlackjackSEOYolo from './BlackjackSEOYolo';
import BlackjackSEOFun88 from './BlackjackSEOFun88';


const BlackjackSEO = (props) => {
  const { app: { brandId } } = props;

  let domainURL = 'https://www.yolo247.site';

  if (brandId === 32) {
    domainURL = 'https://www.yolo247.club';
  }
  else if (brandId === 33) {
    domainURL = 'https://www.iw247.com';
  }
  else if (brandId === 34) {
    domainURL = 'https://www.betdaily.club';
  }
  else if (brandId === 39) {
    domainURL = 'https://www.fomo7.net';
  } else if (brandId === 40) {
    domainURL = 'https://www.fomo7.pro';
  }
  else if (brandId === 46) {
    domainURL = 'https://www.fun88.site';
  }
  else if (brandId === 47) {
      domainURL = 'https://www.gofun88.in';
  }

  const domain = (brandId === 31 || brandId === 32) ? 'Yolo247' : brandId === 33 ? 'IW247' : (brandId === 39 || brandId === 40) ? 'FOMO7' : (brandId === 46 || brandId === 47) ? 'FUN88' : 'Betdaily';



  return (
    <>
      {/* <Helmet>
        <script type="application/ld+json">
          {
            structuredData(domainURL)
          }

        </script>
      </Helmet> */}
      {
        (brandId === 33 || brandId === 40 || brandId === 46) ? <></> :
        (brandId === 39) ? <BlackJackSEO39 /> :
        (brandId === 47) ? <BlackjackSEOFun88 /> :
        <BlackjackSEOYolo />
      }
    </>
  )
}

export default withAppContext(BlackjackSEO);