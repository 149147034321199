import { useEffect, Suspense, lazy } from "react";
import FooterContainer from "src/components/footer/footer";
import getSchemaData from "src/components/schema";
import SEOContents from "src/components/SEOContents";
import { withAppContext } from "src/store/initAppContext";
import styled, { withTheme } from "styled-components";
import { theme } from "styled-tools";
import { Helmet } from "react-helmet";


const structuredData = (brandId: any) => {
	const brandUrlConfig = {
		32: 'https://www.yolo247.club',
		33: 'https://www.iw247.com',
		34: 'https://www.betdaily.club',
		39: 'https://www.fomo7.net',
		40: 'https://www.fomo7.pro',
		46: 'https://www.fun88.site',
		47: 'https://www.gofun88.in',
	};

	const domainURL = brandUrlConfig[brandId] || 'https://www.yolo247.site';

	const otherschemaData = getSchemaData('privacy-policy');

	const data = [{
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement":
			[
				{
					"@type": "ListItem",
					"item": `${domainURL}`,
					"name": "Homepage",
					"position": 1
				},
				{
					"@type": "ListItem",
					"item": `${domainURL}/privacy-policy`,
					"name": `About Us`,
					"position": 2
				}
			],
		"name": "Breadcrumbs"
	},
	...otherschemaData
	];

	return JSON.stringify(data)
};



function PrivacyPolicy(props: any) {

    const {
        app: { brandId },
    } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    })

    const Contents = brandId && brandId === 32 ? lazy(() => import("src/components/fine_print/privacy32")) : 
	brandId && (brandId === 39) ? lazy(() => import("src/components/fine_print/privacy39")) : 
	(brandId === 40) ? lazy(() => import("src/components/fine_print/privacy40")) :
	(brandId === 46 || brandId === 47) ? lazy(() => import("src/components/fine_print/privacyfun88")) :
	lazy(() => import("src/components/fine_print/privacy31"))


    return (
        <>
			<Helmet>
				<script type="application/ld+json">
					{
						structuredData(brandId)
					}
				</script>
			</Helmet>
            <ContentWrapper>
				<SEOContents pageName={"privacyPolicy"}/>
                <Suspense fallback={<div>Loading ... </div>}>
                    <Contents />
                </Suspense>
            </ContentWrapper>
            <FooterContainer />
        </>
    )
}

export default withTheme(withAppContext(PrivacyPolicy));

const ContentWrapper = styled.div`
	max-width: 800px;
	margin: 0 auto;
	font-size: 12px;
	padding: 5.5em 1.25em;
    text-transform: capitalize;

    h1{
		color: ${theme('palette.seoContenAccordionInnerAngerTagColor')};
    }

    p,
	h3,
	h4,
	h5,
	h6 {
		color:${theme('palette.nametext')};
		margin-top: 1.23em;
	}

	h1 {
		font-size: 18px;
	}

	h2 {
		font-size: 16px;
	}

	h3 {
		font-size: 14px;
	}

	ul ul,
	ol {
		margin: 1em 0;
	}

	li {
		color:${theme('palette.nametext')};		margin: 0.75em 0;
	}

	a {
		color: #EEBC15;
		text-decoration: underline;
	}

    span{
        font-weight: 700;
    }

	.brandColor {
		color: #0366fd;
		text-transform: lowercase;
		font-weight: bold;
	}
`;