// Library Imports
import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import styled, { css, withTheme } from 'styled-components';
import { theme } from "styled-tools";
import { useLazyQuery } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";
// Component Imports 
import Intl from "src/components/common/Intl";
import GlobalModal from "src/components/GlobalModal";
import { LoginInput, PlaceHolder } from "src/components/Auth/Login/Login";
import { Input } from "src/components/udb/commonStyle";
import { AuthButtonWrap, AuthSubmitBtn, BackButton, TextHeadVertical, Tittle } from "src/components/Auth/commonStyles";

// Global Imports 
import config from "src/config/config";
import { AES, pad, mode, enc } from "crypto-js";

// Images Components
import HorseImage from "src/images/login_horse.png";
// import Logo from "src/images/desktop_logo.gif";

// Util Imports
import util from "src/util/util";
import cookieUtil from "src/util/cookieUtil";
import { ECookieName } from "src/util/utilModel";

// Other Imports
import { GET_USERINFO } from "src/graphql/member";
import { withAppContext } from "src/store/initAppContext";
import ForgotPassword from "./Signup/forgotPassword";
import ForgetUsername from "./ForgotUserName/forgetUsername";
import SignUp from "./Signup/Signup";
import { login, resendOtp } from "src/config/api";
import EyeIcon from 'src/components/icon/Eye';
import HideIcon from 'src/components/icon/Hide';
import SEOContents from "src/components/SEOContents";
import {SRTM} from 'src/util/sportradarEvent';
import { Helmet } from "react-helmet";
import { CRMLoginSuccess, CRMPageVisit } from "src/util/crmUtil";
import FomoSponsorsSlider from "./FomoSponsorsSlider";
import getSchemaData from "src/components/schema";


export type UserOptions = {
  phoneNumber: string,
};

type StyleProps = {
  activeTab: any;
}

const structuredData = (brandId) => {
  let domainURL = 'https://www.yolo247.site';

  if (brandId === 32) {
    domainURL = 'https://www.yolo247.club';
  }
  else if (brandId === 33) {
    domainURL = 'https://www.iw247.com';
  }
  else if (brandId === 34) {
    domainURL = 'https://www.betdaily.club';
  }
  else if (brandId === 39) {
    domainURL = 'https://www.fomo7.net';
} else if (brandId === 40) {
  domainURL = 'https://www.fomo7.pro';
}
else if (brandId === 46) {
  domainURL = 'https://www.fun88.site';
}
else if (brandId === 47) {
  domainURL = 'https://www.gofun88.in';
}

const otherschemaData = getSchemaData('login');

  const data = [
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement":
        [
          {
            "@type": "ListItem",
            "item": `${domainURL}`,
            "name": "Homepage",
            "position": 1
          },
          {
            "@type": "ListItem",
            "item": `${domainURL}/login`,
            "name": "Login",
            "position": 2
          }
        ],
      "name": "Breadcrumbs"
    },
    ...otherschemaData
  ];

  return JSON.stringify(data)
}

const tabs = [
  {
    id: 'username',
    activeIcon: 'https://opt-v3-files.raksahb.com/static/mobile/brand/39/image/userIconActiveFun.png',
    inActiveIcon: "https://opt-v3-files.raksahb.com/static/mobile/brand/39/image/userIconInActiveFun.png",
    label: 'User ID'
  },
  {
    id: 'mobile',
    activeIcon: 'https://opt-v3-files.raksahb.com/static/mobile/brand/39/image/mobileIconActiveFun.png',
    inActiveIcon: "https://opt-v3-files.raksahb.com/static/mobile/brand/39/image/phoneIconInActiveFun.png",
    label: 'Mobile No'
  },
  {
    id: 'email',
    activeIcon: 'https://opt-v3-files.raksahb.com/static/mobile/brand/39/image/emailIconActiveFun.png',
    inActiveIcon: "https://opt-v3-files.raksahb.com/static/mobile/brand/39/image/emailIconInActiveFun.png",
    label: 'Email'
  }
];


function LoginPage(props) {
  const navigate = useNavigate()
  const {
    watch,
  } = useForm({
    mode: "onChange",
  });
  const TOKEN_SECRET_KEY = config.secretKey;
  // const watchMobileNumber = watch("MobileNumber", 0);
  const { app: { setState, handleLogin, handleSignup, showTimer, showResend, brandId },theme } = props
  const [page, setPage] = useState(0);
  const [isModalOpen, setModal] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [token, setToken] = useState(null);

  const [getUserInfo, { loading, error, data }] = useLazyQuery(GET_USERINFO, {
    fetchPolicy: "network-only",
    onError: (error) => {
      setErrorMessage(error.message)
    },
    onCompleted: (data) => {
      handleSuccess(data);
    },
  });
  const [otpLoader, setOtpLoader] = useState(false);
  const [otpHandle, setOtpHandle] = useState(false);
  const [networkMessage, setNetworkMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false)
  let userName = cookieUtil.get(ECookieName.COOKIE_LOGGEDIN_USERNAME)
  userName = cookieUtil.decryptKey(ECookieName.COOKIE_LOGGEDIN_USERNAME, userName);

  const [isAuthenticated, setAuthenticated] = useState(false)

    //for login with username,mobile,&email
    const [activeTab, setActiveTab] = useState('username');

    const getRememberedUsername = (activeTab: string, rememberedValue: any) => {
      // Extract the username part from the remembered value
      const rememberedUsername = rememberedValue ? rememberedValue.split("/")[0] : "";
    
      // Validation patterns
      const isMobileNumber = /^[0-9]{10}$/; // Mobile: 10 digits
      const isEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // Email format
      const isUsername = /^(?!^\d{10}$)[a-zA-Z0-9]*$/; // Username: Alphanumeric, no special characters, not a 10-digit number
    
      // Validate and return based on activeTab
      if (activeTab === "mobile" && isMobileNumber.test(rememberedUsername)) {
        return rememberedUsername;
      } else if (activeTab === "email" && isEmail.test(rememberedUsername)) {
        return rememberedUsername;
      } else if (activeTab === "username" && isUsername.test(rememberedUsername)) {
        return rememberedUsername;
      }
    
      // Return empty string if no valid match
      return "";
    };

  const [userData, setUserData] = useState({
    first_time_login: false,
    first_time_deposit:false,
    is_change_password: false,
    user_registered_at:''
  })
  const { config: { showsignup = true,showNotAble2Login = true, showFomoSponsorSlider, showLoginTab, showOnlyOnFun } } = theme;

  // Check for modal Props here
  useEffect(() => {
    if (handleLogin) {
      triggerModal(true)
    }
    return () => {
      setState({ handleSignup: false });
      setState({ showTimer: false });
      setState({ showResend: false })
    }
  }, [handleLogin])



  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (cookieUtil.get(ECookieName.COOKIE_TOKEN)) {
      navigate('/')
    }

    //sport radar
    SRTM.init(brandId);
    //for CRM
    CRMPageVisit();
  }, [])

  const triggerModal = (value: boolean) => {
    setModal(value);
  };

  const handleCallback = (childData) => {
    setPage(childData);
    triggerModal(true)
  };

  const closeModal = () => {
    triggerModal(false);
    setPage(0);
  };

  const modalHandler = () => {
    if (typeof setState === 'function') {
      let _reqData = {
        handleSignup: true
      }
      setState(_reqData)
    }
    closeModal()
  }

  const sendOTP = async (event: React.MouseEvent<HTMLElement>, formData) => {
    // setTimeout(() => {
      if (typeof setState === 'function') {
        let _reqData = {
         
          showResend: false
        }
        setState(_reqData)
      }
    // }, 30000);
    event.preventDefault();
    event.stopPropagation();
    setOtpLoader(true)
    setOtpHandle(true);
    const formvalue = formData()
    const info = JSON.stringify({
      phoneNumber: formvalue.MobileNumber,
      phoneCountry: "in",
      "brandId":brandId
    });
    const encryptedTarget = enc.Utf8.parse(info);
    const encryptSecretKey = enc.Utf8.parse(TOKEN_SECRET_KEY);
    const authInfo = AES.encrypt(encryptedTarget, encryptSecretKey, {
      iv: enc.Utf8.parse(""),
      padding: pad.Pkcs7,
      mode: mode.CBC,
    }).ciphertext.toString();
    let data = {
      resetInfo: authInfo,
    };
    resendOtp(data)
      .then((response) => {
        setOtpLoader(false);
        setIsSuccess(true);
        if (typeof setState === 'function') {
          let _reqData = {
            showTimer: true,
            showResend: false
          }
          setState(_reqData)
        }
        setTimeout(() => {
          if (typeof setState === 'function') {
            let _reqData = {
              showTimer: false,
              showResend: true
            }
            setState(_reqData)
          }
        }, 75000);
      })
      .catch((error) => {
        let errorResponse = error.response?.data
        setOtpHandle(false);
        setNetworkMessage(errorResponse?.error);
        setOtpLoader(false)
      })
  };

  const handleSuccess = (data) => {
    const payload = util.getValue(data, ["member", "userInfo"], {});
    if (payload.length !== 0) {
      cookieUtil.set(ECookieName.COOKIE_USERNAME, payload.username, 30);
      cookieUtil.set(ECookieName.COOKIE_MEMBERID, payload.memberId, 30);
      cookieUtil.set(
        ECookieName.COOKIE_ORIGINAL_MEMBERID,
        payload.memberId,
        30
      );
      cookieUtil.set(
        ECookieName.COOKIE_ORDER_LIMIT,
        JSON.stringify(payload.orderLimit),
        30
      );
      cookieUtil.set(ECookieName.COOKIE_LANG, "en-us", 30);
      cookieUtil.set(
        ECookieName.COOKIE_EXCHANGE_RATE,
        payload.exchangeRate,
        30
      );
      cookieUtil.set(ECookieName.COOKIE_ACCOUNT_TYPE, "CLIENT");
      cookieUtil.set(
        ECookieName.COOKIE_IS_SUBACCOUNT,
        payload.isSubAccount ? "1" : ""
      );
      cookieUtil.set(
        ECookieName.COOKIE_TOKEN,
        token ? token : localStorage.getItem(ECookieName.COOKIE_TOKEN)
      );
      cookieUtil.set(
        ECookieName.COOKIE_ACCOCIATIONS,
        JSON.stringify(payload.associations || [])
      );
      cookieUtil.set(
        ECookieName.COOKIE_USERINFO,
        JSON.stringify(payload || {})
      );
      cookieUtil.set(
        ECookieName.COOKIE_BFPT_INFO,
        JSON.stringify(
          payload.betfairInfo.map(
            (_: { betfairPt: number; categoryId: number }) => ({
              bfPt: _.betfairPt,
              categoryId: _.categoryId,
            })
          )
        ),
        30
      );
      cookieUtil.set(
        ECookieName.COOKIE_BRAND_ID,
        payload.brandId ? payload.brandId : brandId
      );
      //setting for showing/hiding change pWD popup
      cookieUtil.set(
        ECookieName.COOKIE_IS_DEFAULT_PASSWORD,JSON.stringify(userData.is_change_password)
      );

      // CRMLoginSuccess(brandId,payload.username, payload.memberId); //CRM Login Succes
      sessionStorage.setItem('uin', encodeURI((JSON.stringify({brandId,username:payload.username,memberId:payload.memberId}))));
  
      navigate('/', {state:{
        first:userData.first_time_login,
        fd:userData.first_time_deposit,
        fpwd: userData.is_change_password,
        iplPopup:userData.user_registered_at
      }});
      window.location.reload();

      //sportrader
      SRTM.loginEvent(brandId,payload.username)
    }
  };

  const onSubmit = ({ username, password, remember_me, ageValid }, event) => {
    if (remember_me) {
      cookieUtil.set(ECookieName.COOKIE_LOGGEDIN_USERNAME, cookieUtil.encryptKey(ECookieName.COOKIE_LOGGEDIN_USERNAME, `${username}/${password}`), 30);
    }
    event.preventDefault();
    event.stopPropagation();
    let loginKey = activeTab === "username" ? "username" : activeTab === "mobile" ? "phoneNumber" : activeTab === "email" ? "email" : "username";
    let userInfo = {
      [loginKey]: username,
      password,
      brandId
    };

    //encrypting the login user info 
    const info = JSON.stringify(userInfo);
    const encryptedTarget = enc.Utf8.parse(info);
    const encryptSecretKey = enc.Utf8.parse(TOKEN_SECRET_KEY);
    const authInfo = AES.encrypt(encryptedTarget, encryptSecretKey, {
      iv: enc.Utf8.parse(""),
      padding: pad.Pkcs7,
      mode: mode.CBC,
    }).ciphertext.toString();

    let data = {
      loginInfo: authInfo,
    };

    login(data)
      .then((response: any) => {
        let res = response.data;
        setToken(res.token);
        
        setUserData({
          first_time_login: res.is_first_time_login,
          first_time_deposit:res.is_first_time_deposit,
          is_change_password: res.is_change_password,
          user_registered_at:res.user_registered_at
        })

        if (brandId == 32 || brandId==34 || brandId === 40|| brandId === 46) {
          sessionStorage.setItem('clubUserCreated', res.user_registered_at)
        }
        
        getUserInfo({
          variables: {
            input: {
              token: res.token,
            },
          },
        });

      })
      .catch((error) => {
        let errormessage = error.response.data
        setNetworkMessage(errormessage.error)
      })
  };


  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    register,
    clearErrors,
    setValue
  } = useForm({
    mode: "all",
    defaultValues: {
      username: userName.split("/")[0] ? userName.split("/")[0] : "",
      password: userName ? userName.split('/')[1] : "",
      remember_me: "",
      ageValid: true,
    },
  });

  // Clear input when activeTab changes
  useEffect(() => {
    // setValue("username", ""); // Clear the username field
    const rememberedUsername = getRememberedUsername(activeTab, userName);
    setValue("username", rememberedUsername, { shouldValidate: true }); // Update dynamically
    clearErrors("username"); // Clear related validation errors
  }, [activeTab, setValue, clearErrors]);

  if (loading) return '';
  if (error) {
    setErrorMessage(error.message);
  }

  const handleLoginButton = () => {
    setPage(1);
    if (typeof setState === 'function') {
      let _reqData = {
        showTimer: false,
        showResend: false
      }
      setState(_reqData)
    }
  }

  const handleBackButton = () => {
    setPage(page - 1);
    if (typeof setState === 'function') {
      let _reqData = {
        showTimer: false,
        showResend: false
      }
      setState(_reqData)
    }
  }

  const handleUsernameClick = () => {
    setPage(2);
    if (typeof setState === 'function') {
      let _reqData = {
        showTimer: false,
        showResend: false
      }
      setState(_reqData)
    }
  }

  const handlepasswordClick = () => {
    setPage(1);
    if (typeof setState === 'function') {
      let _reqData = {
        showTimer: false,
        showResend: false
      }
      setState(_reqData)
    }
  }


  const formatInput = (e) => {
    // return /\s/g.test(e);
        // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
        let checkIfNum;
        
        if (e.key !== undefined) {
          // Check if it's a "e", ".", "+" or "-"
          checkIfNum =
            e.key === " " ||
            e.key === "." ||
            e.key === "+" ||
            e.key === "-" ||
            e.key === "ArrowUp" ||
            e.key === "ArrowDown" ||
            e.key === " ";
        } else if (e.keyCode !== undefined) {
          // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
          checkIfNum =
            e.keyCode === 69 ||
            e.keyCode === 190 ||
            e.keyCode === 187 ||
            e.keyCode === 189;
        }
        return checkIfNum && e.preventDefault();
  }


  const handleEkey=(e)=>{
        let checkIfNum;
        
        if (e.key !== undefined) {
          checkIfNum =
            e.key === "e" ||
            e.key === " " ||
            e.key === "." ||
            e.key === "+" ||
            e.key === "-" ||
            e.key === "ArrowUp" ||
            e.key === "ArrowDown" ||
            e.key === " " || 
            e.key === "E";
        } else if (e.keyCode !== undefined) {
          // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
          checkIfNum =
            e.keyCode === 69 ||
            e.keyCode === 190 ||
            e.keyCode === 187 ||
            e.keyCode === 189;
        }
        return checkIfNum && e.preventDefault();
  }
  



  // const closeTootltip = () => {
  //   setTooltipState(false);
  // }
  return (
    <div>
      {/* <LoginButton>
        <button type="button">
          <Intl langKey="LOGIN@TITLE" />
        </button>
      </LoginButton> */}
      <Helmet>
        <script type="application/ld+json">
          {
            structuredData(brandId)
          }

        </script>
      </Helmet>
      <SEOContents pageName={"login"}/>
      <GlobalModal
        isModalOpen={isModalOpen}
        closeModal={(value) => navigate(-1)}
      >
        {errorMessage}
        <LoginContentWrapper className="loginModal">
          <LeftBlock>
            {page === 0 &&
              <LoginTitleWrap>
                <Tittle>
                  <Intl langKey="LOGIN@TITLE" />
                </Tittle>
              </LoginTitleWrap>}
            {page === 1 && (
              <TextHeadVertical>
                <Tittle>
                  <Intl langKey="LOGIN@PASSWORD@LABEL" />
                </Tittle>
              </TextHeadVertical>
            )}
            {page === 2 && (
              <TextHeadVertical>
                <Tittle>
                  <Intl langKey="LOGIN@USERNAME@LABEL" />
                </Tittle>
              </TextHeadVertical>
            )}
            <LoginTopWrap>
              <LoginTopLeftBlock>
                <Link className="loginSignup_logo" to="/">
                  {brandId == 33 || brandId == 34 ? <img src={`${config.imageV3Host}/static/cashsite/${theme.image.new_whitelable_logo_betdaily}`}  className='betdaily' alt="" /> : brandId == 39 || brandId == 40 ? <img src={`${config.imageV3Host}/static/cashsite/${theme.image.new_whitelable_logo_fomo}`}  className='betdaily' alt="" /> : <img src={`${config.imageV3Host}/static/cashsite/${theme.image.new_whitelable_logo}`} alt="Logo" />}
                </Link>

              </LoginTopLeftBlock>
              {page === 0 &&  (
                <LoginTopRightBlock>
                 {showsignup && <p className="clickHereSign">
                    {" "}
                    <Intl langKey="AUTH@CLICKHERETO" />
                    <button onClick={() => navigate('/signup')} className=""><Intl langKey="SIGNUP@TITLE" /></button>{" "}
                  </p>}
                </LoginTopRightBlock>
              )}
              {page === 1 && (
                <LoginTopRightBlock>
                  <p className="clickHereSign">
                    {" "}
                    <Intl langKey="FORGOT@USERNAME" />
                    <button onClick={handleUsernameClick}><Intl langKey="AUTH@CLICKHERE" /></button>
                  </p>
                </LoginTopRightBlock>
              )}
              {page === 2 && (
                <LoginTopRightBlock>
                  <p className="clickHereSign">
                    {" "}
                    <Intl langKey="FORGOT@PASSWORD" />
                    <button onClick={handlepasswordClick}><Intl langKey="AUTH@CLICKHERE" /></button>
                  </p>
                </LoginTopRightBlock>
              )}
            </LoginTopWrap>

            <LoginDetailsWrap activeTab={activeTab}>

              {page === 0 && (
                <>
                  <form onSubmit={handleSubmit(onSubmit)}>
                  {showLoginTab &&
                        <TabContainer>
                          {tabs.map((tab: any) => (
                            <Tab
                              key={tab.id}
                              activeTab={activeTab === tab.id}
                              onClick={() => setActiveTab(tab.id)}
                            >
                              <img src={activeTab === tab.id ? tab.activeIcon : tab.inActiveIcon} alt={tab.id} />
                              <span>{tab.label}</span>
                            </Tab>
                          ))}
                        </TabContainer>
                    }
                    <Controller
                      key={activeTab}
                      control={control}
                      name="username"
                      // rules={{
                      //   required: {
                      //     value: true,
                      //     message: "Please enter username",
                      //   },
                      //   pattern: {
                      //     value: /^[a-zA-Z0-9]*$/,
                      //     message:
                      //       "* Name must not contain special characters and spaces",
                      //   },
                      //   minLength: {
                      //     value: 4,
                      //     message: "Minimum Character : 4",
                      //   },
                      // }}
                      rules={{
                        required: `Please enter ${activeTab}`,
                        pattern: {
                          value:
                            activeTab === "mobile"
                              ? /^[6-9]\d{9}$/ // Only digits for mobile numbers
                              : activeTab === "email"
                                ? /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/ // Email pattern
                                : /^[a-zA-Z0-9]*$/, // Default for username (no special characters or spaces)
                          message:
                            activeTab === "mobile"
                              ? "Mobile number must contain only digits"
                              : activeTab === "email"
                                ? "Please enter a valid email address"
                                : "* Name must not contain special characters and spaces",
                        },
                        minLength: {
                          value: activeTab === "mobile" ? 10 : 4, // Minimum 10 digits for mobile, 4 for others
                          message:
                            activeTab === "mobile"
                              ? "Mobile number must be at least 10 digits"
                              : "Minimum Character: 4",
                        },
                        maxLength: {
                          value:
                            activeTab === "mobile"
                              ? 10 
                              : activeTab === "username"
                              ? 18 
                              : activeTab === "email"
                              ? 50 
                              : 20, 
                          message:
                            activeTab === "mobile"
                              ? "Too Many Characters"
                              : activeTab === "username"
                              ? "Maximum Character: 18"
                              : activeTab === "email"
                              ? "Maximum Character: 50"
                              : "Maximum Character: 20",
                        },
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <>
                          <LoginInput
                            id="username"
                            placeholder={<Intl langKey={activeTab === "mobile" ? "LOGIN@MOBILE" : activeTab === "email" ? "LOGIN@EMAILID" : activeTab === "username" ? "LOGIN@USERNAME" : "LOGIN@ID"} />}
                            type={
                              activeTab === "mobile"
                                ? "number" 
                                : activeTab === "email"
                                  ? "email" 
                                  : activeTab === "username"
                                    ? "text" 
                                    : "text" 
                            }
                            onChange={onChange}
                            onKeyDown={
                              activeTab === "username"
                                ? formatInput
                                : activeTab === "mobile"
                                ? handleEkey
                                : undefined
                            }
                            value={value}
                            error={error}
                            className={"customInput"}
                            req={true}
                            name={"username"}
                            activeTab={activeTab}
                          />
                          <h1>{error && error.message}</h1>
                        </>
                      )}
                    />

                    <Controller
                      control={control}
                      name="password"
                      rules={{
                        required: {
                          value: true,
                          message: "Please enter password",
                        },
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <SignUpPasswrdWrap>
                          <LoginInput
                          //  style={{border:'1px solid red'}}
                            id="password"
                            placeholder={<Intl langKey="LOGIN@PASSWORD" />}
                            onChange={onChange}
                            value={value}
                            error={error}
                            req={true}
                            name={"password"}
                            type={values.showPassword ? "text" : "password"}
                            className={"customInputPassword"}
                          />
                          <PasswordHide onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >{values.showPassword ? <HideIcon /> : <EyeIcon />}
                          </PasswordHide>
                          <h1>{error && error.message}</h1>
                        </SignUpPasswrdWrap>
                      )}
                    />

                    <LoginBottomWrap>
                      <Controller
                        control={control}
                        name="remember_me"
                        rules={{
                          required: false,
                        }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <RadioBtnWrapper style={{width:"35%"}}>
                            <BtnText>
                              <Input
                                type="checkbox"
                                name="default"
                                value={value}
                                onClick={onChange}
                              />
                              <Intl langKey="LOGIN@REMEMBER_ACCOUNT" />
                            </BtnText>
                          </RadioBtnWrapper>
                        )}
                      />

                      <Controller
                        control={control}
                        name="ageValid"
                        rules={{
                          required: {
                            value: true,
                            message: "Please Confirm You Are Above 18 Years Old",
                          },
                        }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <>
                            <RadioBtnWrapper style={{width:"63%"}}>
                              <BtnText>
                                <Input
                                  {...register("ageValid")}
                                  type="checkbox"
                                  name="ageValid"
                                  value={props.value}
                                  checked={props.value}
                                  onClick={onChange}
                                />
                                <div className="agreeAge">
                                  <Intl langKey="LOGIN@CHECKBOX" />
                                  <span className="agreeAgeColor"><Intl langKey="LOGIN@CHECKBOX2" /></span>&
                                  <span className="agreeAgeColor"><Intl langKey="LOGIN@CHECKBOX3" /></span>
                                </div>
                              </BtnText>
                            </RadioBtnWrapper>
                            {error && <p className = {brandId == 46 || brandId == 47 ? "errorContent" : "" }>{error && error.message}</p>}
                          </>
                        )}
                      />
                    </LoginBottomWrap>

                    <ErrorWrap>
                      {networkMessage && networkMessage.length !== 0 && <p className="username-error1">{networkMessage}</p>}
                    </ErrorWrap>

                    <AuthButtonWrap>
                      <AuthSubmitBtn disabled={!isValid} type="submit">
                        <Intl langKey="LOGIN@TITLE" />
                      </AuthSubmitBtn>
                    </AuthButtonWrap>

                  </form>

                  {showNotAble2Login && <LoginFooterMessage>
                    <p>
                      <Intl langKey="LOGIN@NOTABLE" />
                      <button onClick={handleLoginButton} className="btmClickLogin"> <Intl langKey="AUTH@CLICKHERE" /></button>
                    </p>
                  </LoginFooterMessage>}

                </>
              )}
              {page === 1 && <ForgotPassword isSuccess={isSuccess} onSendOtpHandler={sendOTP} otpHandle={otpHandle} setOtpLoader={otpLoader} loading={loading} showResend={showResend} showTimer={showTimer} parentCallback={handleCallback} />}
              {page === 2 && <ForgetUsername isSuccess={isSuccess} parentCallback={handleCallback} onSendOtpHandler={sendOTP} otpHandle={otpHandle} setOtpLoader={otpLoader} loading={loading} />}
              {page === 3 && <SignUp parentCallback={handleCallback} />}

            </LoginDetailsWrap>
            <BackButton>
              <button
                className="backbutton"
                onClick={page === 1 || page === 2 ? handleBackButton : null}
                style={page === 1 || page === 2 ? { display: "block" } : { display: "none" }}
              >
                {page === 1 || page === 2 ? <Intl langKey="AUTH@BACK" /> : null}
              </button>
            </BackButton>
          </LeftBlock>
          {showFomoSponsorSlider && <RightBlockWrapper>
            <FomoSponsorsSlider />
          </RightBlockWrapper>}

          <button
            className="close"
            type="button"
            onClick={() => navigate('/')}
          ></button>

        </LoginContentWrapper>
      </GlobalModal>
    </div>
  );
}

export default withTheme(withAppContext(LoginPage));

const SignUpPasswrdWrap = styled.div`
    position: relative;
`;

// const LoginButton = styled.div`
//   transform: skew(340deg);
//   // border: 1px solid #CB9640;
//   margin-left: 20px;
//   // background: ${theme('palette.primary-background-color-linear-new')};
//   // box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.25);
//   background: #6778E3;
//   border-radius: 5px;
  
//     button {
//         width: 80px;
//         text-align: center;
//         display: block;
//         text-decoration: none;
//         padding: 10px 0px;
//         font-size: 14px;
//         transform: skew(-340deg);
//         color: ${theme('palette.logintextcolor-new')};
        
//         text-transform: uppercase;
//         background-color: transparent;
//         border: none;
//         cursor: pointer;
//     }
// `;

export const LoginTopLeftBlock = styled.div`
  width: 50%;

  & img {
    width: 120px;
    padding: 5px 15px;
  }
  .betdaily{
    width: 150px;
    padding: 5px 0px 0px 15px;
  }
  
`;

export const LoginTopWrap = styled.div`
  /* background:#323232; */
  background: ${theme('palette.loginTopHeadWrapperBg')};
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: ${theme('palette.loginTopHeadWrapperPadding')};
  border-radius: 10px 10px 0px 0px;
`;

const LoginBottomWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 5px 20px 1px 20px;
  flex-wrap: ${theme('palette.loginBottomWrapFlexWrap')};

  & p {
    font-size: 12px;
    color: red;
    margin: 4px 0px;
  }

  .agreeAge {
    width: 100%;
  }

  .errorContent {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 5px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgb(232 232 232) 50%, rgba(255, 255, 255, 1) 100%);
  }
`;

export const LoginTitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
 background: ${theme('palette.secondary-background-color-linear-new')};
  padding: 5px 15px;
  position: absolute;
   left: -36px;
  -ms-transform: rotate(20deg);
  transform: rotate(270deg);
  height: 25px;
`;

// const LoginTitle = styled.h3`
//   letter-spacing: 0.1em;
//   text-transform: uppercase;
//   font-weight: bold;
//   font-size: 20px;
//   position: relative;
//   top: -12px;
//   color: #fff;
// `;

export const LoginTopRightBlock = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  .clickHereSign {
    color: ${theme('palette.clickHereSignColor')};
    font-style: ${theme('palette.clickHereSignFontStyle')};
    font-weight: ${theme('palette.clickHereSignFontWeight')};
  }
  
  & span {
    text-decoration: underline;
    color: #ffd500;
    /* font-family: "Roboto"; */
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
  }

  & p {
    color: ${theme('palette.nametext')};
    /* font-family: "Roboto"; */
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    padding-right: 5px;
    text-transform: capitalize;
    margin: 0px;
  }

  button {
    font-weight: 500;
    font-size: 14px;
    text-decoration: underline;
    font-style: italic;
    cursor: pointer;
    background: transparent;
    border: none;
    /* color: orange; */
    color: ${theme('palette.AuthSignupTextColor')};
    text-decoration: underline;
    font-weight: ${theme('palette.clickHereSignFontWeight')};
  }

  .btmClickLogin {
    color: ${theme('palette.AuthBtmClickLoginColor')};
    font-style: ${theme('palette.AuthBtmClickLoginFontStyle')};
  }
`;

export const LoginDetailsWrap = styled.div<Pick<StyleProps, "activeTab">>`
  margin: auto;
  position: relative;
  width: 85%;
  padding: 30px 15px 30px 20px;

  & h1 {
    font-size: 13px;
    color: red;
    margin: 3px 0px;
  }
  .bdwgbr{
    background: ${theme('palette.loginFields')};
    }

    & .customInput {
     background: #EBEBEB;
     padding: 20px 15px;
     box-shadow: none;

     .countryCode {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 6px;
      gap: 1px;
      color: #000;

      ${(props) => (props.activeTab) === "mobile" && css`
            &:before {
              content: "";
              width: 15px;
              height: 15px;
              display: flex;
              background-image: url(${config.imageV3Host}/static/cashsite/brand/6/icon/loginMobileInputFlagIcon.svg);
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat; 
              /* position: absolute;
              left: -15px; */
              align-items: center;
              justify-content: center;
              top: -1px;
            }
        `}
     }

     &::before {
        content: "";
        width: 15px;
        height: 15px; 
        background-image: ${(props) =>
    (props.activeTab) === "mobile"
      ? `url(${config.imageV3Host}/static/mobile/brand/39/image/loginMobileFunIcon.png)`
      : (props.activeTab) === "email"
        ? `url(${config.imageV3Host}/static/mobile/brand/39/image/loginEmailFunIcon.png)`
        : (props.activeTab) === "username"
          ? `url(${config.imageV3Host}/static/mobile/brand/39/image/loginUsernameFunIcon.png)`
          : (props.activeTab) === "password"
            ? `url(${config.imageV3Host}/static/mobile/brand/39/image/loginUsernameFunIcon.png)`
            : "none"};

        background-position: center;
        background-size: contain;
        background-repeat: no-repeat; 
    }

     ${PlaceHolder} {
          color: rgba(0, 0, 0, 0.60) !important;
          margin-left: ${(props) => (props.activeTab) === "mobile" ? "67px" : "20px"};
      }

     & input {
       color: #000000;
       /* margin-left: 4px; */
       margin-left: ${(props) => (props.activeTab) === "mobile" ? "-2px" : "4px"};
     }
   }

   & .customInputPassword {
     background: #EBEBEB;
     padding: 20px 15px;
     box-shadow: none;

     &::before {
        content: "";
        width: 15px;
        height: 15px; 
        background-image: url(${config.imageV3Host}/static/mobile/brand/39/image/loginPasswordFunIcon.png) !important;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat; 
    }

     ${PlaceHolder} {
          color: rgba(0, 0, 0, 0.60) !important;
          margin-left: 20px;
      }

     & input {
       color: #000000;
       margin-left: 4px;
       width: 85%;
     }
   }
`;

const RadioBtnWrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  padding: 0px;

  ${Input} {
    width: 18px !important;
    height: 18px !important;
    border: 1px solid #4643c2;
    margin: 0;
    margin-right: 5px;
    cursor: pointer;
  }
  & input[type=checkbox] {
        accent-color: ${theme('palette.AuthCheckboxColor')};
    }
`;

export const LoginContentWrapper = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.AuthWrapBgNewIPL')});
  background-size: cover;
  /* background-position: right; */
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  z-index: 99999;

  .bdwgbr{
    background: ${theme('palette.loginFields')};
  }

  .login_logo {
    width: 290px;
    height: 190px;
    display: flex;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    right: -8px;
    top: 0px;
  }

  .horse_bg {
    width: 550px;
    height: 475px;
    display: flex;
    background-image: url(${config.imageS3Host}${HorseImage});
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
`;

export const LeftBlock = styled.div`
  /* width: 550px;
  height: auto;
  max-width: 650px;
  min-height: 350px;
  max-height: 64vh; */
  width: 38%;
  height: 50%;
  /* min-height: auto;
  max-height: none; */
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  backdrop-filter: blur(40.349998474121094px);
  background: ${theme('palette.AuthLoginBackground')};
  /* background: linear-gradient(0deg, #000000, #000000), radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.25) 100%); */
  border-radius: 10px;
  /* margin: 40px 0px 0px 40px; */
  margin: 5% 0% 1% 7%;
  position: relative;

  /* @media (max-width: 1350px) , screen and (min-height: 700px){
    width: 550px;
    max-width: 650px;
    // max-height: 450px;
  }

  @media (max-width: 1220px) {
    width: 550px;
    max-width: 650px;
  } */
`;

const BtnText = styled.label`
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: ${theme('palette.nametext')};
  font-weight: 500;
  font-size: 11px;
  margin-left: 5px;

  .agreeAgeColor {
    color: #F6A72D;
    text-decoration-line: underline;
  }
`;

const LoginFooterMessage = styled(LoginTopRightBlock)`
  width: 100%;
  margin-top: 15px;

  & p,
  span {
    font-style: normal;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 13px;
    font-weight: 400;
  }

  & a {
    text-decoration: underline;
    display: flex;
    align-items: center;

    &:hover {
      text-decoration: underline;
      color: #ffd500 !important;
    }

    & p {
      text-decoration: none;
    }
  }
`

const ErrorWrap = styled.div`
& p {
  font-size: 15px;
  text-align: center;
  color: ${theme('palette.nametext')};
  font-weight: 500;
  background-color: #c91414;
  padding: 5px;
  border-radius: 30px;
}
`;

const PasswordHide = styled.div`
    display: block;
    width: 5px;
    position: absolute;
    right: 25px;
    top: 15px;
`;

const RightBlockWrapper = styled.div`
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;


const TabContainer = styled.div`
  display: flex;
  gap: 16px;
  padding: 0px 0px 16px 0px;
  width: 100%;
  justify-content: space-between;
`;

const Tab = styled.div<Pick<StyleProps, "activeTab">>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 65px;
  border: ${({ activeTab }) => (activeTab ? theme('palette.LoginOptionsTabSelectedBorder') : theme('palette.LoginOptionsTabBorder'))};
  border-radius: 12px;
  background: ${({ activeTab }) => (activeTab ? theme('palette.LoginOptionsTabSelected') : theme('palette.LoginOptionsTabBg'))};
  color: ${({ activeTab }) => (activeTab ? '#fff' : theme('palette.LoginOptionsTabText'))};
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }

  & img {
    width: 100%;
    height: 30px;
    object-fit: contain;
  }

  & span {
    font-size: 11px;
    font-weight: ${({ activeTab }) => (activeTab ? '700' : '500')};
  }
`;
