import React from 'react'
import { Accordion, AccordionWrapper } from '../seo-content/seo_content'
import { withAppContext } from 'src/store/initAppContext';
import { withTheme } from 'styled-components';
import OnlineCasino39 from './onlineCasino39';
import OnlineCasino47 from './onlineCasino47';
import OnlineCasino31 from './onlineCasino31';
import OnlineCasino46 from '../onlineCasino46';

type Props = {
    app: any
}

const ONLINECASINOSEO = (props: Props) => {
    const { app: { brandId } } = props;

    let domainURL = 'https://www.yolo247.site';

    if (brandId === 32) {
        domainURL = 'https://www.yolo247.club';
    }
    else if (brandId === 33) {
        domainURL = 'https://www.iw247.com';
    }
    else if (brandId === 34) {
        domainURL = 'https://www.betdaily.club';
    }
    else if (brandId === 39) {
        domainURL = 'https://www.fomo7.net';
    } 
    else if (brandId === 40) {
        domainURL = 'https://www.fomo7.pro';
    }
    else if (brandId === 46) {
        domainURL = 'https://www.fun88.site';
    }
    else if (brandId === 47) {
        domainURL = 'https://www.gofun88.in';
    }

    const domain = (brandId === 31 || brandId === 32) ? 'Yolo247' : brandId === 33 ? 'iw247' : (brandId === 39 || brandId === 40) ? 'FOMO7': (brandId === 46 || brandId === 47) ? 'FUN88':  'Betdaily' ;
   
    return (
        <>
        {
            (brandId == 33) ? <></> :
            (brandId === 31 || brandId === 32) ? <OnlineCasino31 /> : 
            (brandId === 39 || brandId === 40) ? <OnlineCasino39 /> : 
            (brandId === 46) ? <OnlineCasino46 /> : 
            (brandId === 47) ? <OnlineCasino47 /> : 
            <OnlineCasino31 />
        }
        </>
    )
}

export default withTheme(withAppContext(ONLINECASINOSEO));