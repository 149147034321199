import { useEffect, useState } from "react";
import FooterContainer from "src/components/footer/footer";
// import ABOUTusSEO from "src/components/SEOComponents/aboutusSEO";
import SEOContents from "src/components/SEOContents";
import { withAppContext } from "src/store/initAppContext";
import styled, { withTheme } from "styled-components";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

function AboutUs39(props: any) {
    const { app: { brandId } } = props;

    let domainURL = 'https://www.yolo247.site';

    if (brandId === 32) {
        domainURL = 'https://www.yolo247.club';
    }
    else if (brandId === 33) {
        domainURL = 'https://www.iw247.com';
    }
    else if (brandId === 34) {
        domainURL = 'https://www.betdaily.club';
    }
    else if (brandId === 39) {
        domainURL = 'https://www.fomo7.net';
    } else if (brandId === 40) {
        domainURL = 'https://www.fomo7.pro';
      }

    const domain = brandId === 31 || brandId === 32 ? 'Yolo247' : (brandId === 39 || brandId === 40) ? 'Fomo7' : brandId === 33 ? 'iw247' : 'Betdaily';

    const [sitename, setsitename] = useState('')

    useEffect(() => {
        window.scrollTo(0, 0)

        switch (brandId) {
            case 31:
                setsitename('yolo247')
                break;
            case 32:
                setsitename('yolo247')
                break;
            case 33:
                setsitename('iw247')
                break;
            case 34:
                setsitename('betdaily')
                break;
            case 39:
                setsitename('FOMO7')
                break;
            case 40:
                setsitename('FOMO7')
                break;
            default:
                setsitename('')
                break;
        }
    }, [])

    return (
        <>
            <h1 className="aboutHeading">About us</h1>
            <p>{sitename} is India’s leading online casino and sports betting site, where you can win big everyday. </p>

            <h2>Our Philosophy </h2>
            <p>{sitename} stands for possibility, youthfulness, excitement & joyful experience. We represent the culture of “not missing out!” </p>
            <p>Throw away your Fear Of Missing Out on <Link to="/">{sitename}</Link>! Join our list of happy customers. </p>

            <h2>Our Mission </h2>
            <p>To be a holistic <Link to="/">online betting platform</Link>, providing diverse and immersive gambling experience, through a wide variety of betting options, with safety and fairplay as the key focus. </p>
            <p>We are committed to offer excellent customer service, cutting edge technology and memorable betting experience.</p>

            <h2>Our Services</h2>
            <p>We provide 700+ casino games and <Link to="/betting-exchange">sports betting</Link> options. </p>

            <h3>Casino: </h3>
            <ul>
                <li><Link to="/live-casino">Live Casino</Link></li>
                <li><Link to="/instant-games">Instant Games</Link></li>
                <li><Link to="/slots">Slots</Link></li>
            </ul>

            <h3>Sports Betting: </h3>
            <ul>
                <li><Link to="/betting-exchange">Exchange</Link></li>
                <li><Link to="/sportsbook">Sportsbook</Link></li>
                <li><Link to="/premium-sportsbook">Premium Sportsbook</Link></li>
            </ul>

            <h3>Our Bonuses</h3>
            <ul>
                <li><Link to="/promotions/welcome-bonus">Welcome Bonus</Link> - 700%</li>
                <li><Link to="/promotions/refill-bonus">Redeposit Bonus</Link> - 5%</li>
                <li><Link to="/promotions/cashback-bonus">Lossback Bonus</Link> - 5%</li>
                <li><Link to="/promotions/referral-bonus">Referral Bonus</Link> - 21%</li>
            </ul>

            <h3>Our Policies </h3>
            <ul>
                <li>KYC Policy </li>
                <li>Anti-Money Laundering Policy </li>
                <li>Self-Exclusion Policy</li>
                <li>Responsible Gaming</li>
                <li>Privacy Policy</li>
            </ul>

            <h2>Top 7 Reasons to choose {sitename}</h2>
            <ol>
                <li><strong>Simple</strong> - Our navigation and processes are simple and user-friendly. </li>
                <li><strong>Diverse</strong> - Our betting options are diverse. You name it, we have it. </li>
                <li><strong>Rewarding</strong> - You will get rewards daily, weekly and monthly. </li>
                <li><strong>Safe</strong> - Gameplay, data and transactions are safe and secure. </li>
                <li><strong>Supportive</strong> - Get 24x7 customer support, with an instant chat ready for you. </li>
                <li><strong>Fast</strong> - Your deposits, payouts and withdrawals are faster. </li>
                <li><strong>MORE</strong> - We have more reasons: more bonuses, more opportunities, more exciting, more withdrawals, more winnings and more memories.</li>
            </ol>

            <p>Our customers are our ambassadors so far - we cherish and continue to keep this momentum forever for all new players as well. </p>
            <p>Welcome to the world of winning. Welcome to {sitename}. </p>
            <p>No more waiting. Let’s get into action!!!</p>
            <p>CTA: <Link to="/login">Start winning now.</Link></p>
        </>
    )
}

export default withTheme(withAppContext(AboutUs39));